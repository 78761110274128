body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor-pointer {
  cursor: pointer;
}

.card {
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.2);
}

.zoom:hover {
  overflow: visible;
  transform: scale(4);
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.4);
}

// bootstrap overrides
$theme-colors: (
  'interesting': #9b51e0,
);

@import 'node_modules/bootstrap/scss/bootstrap';
